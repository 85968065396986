import { DispatchType } from '../redux/store';
import { loadUserProfile } from '../redux/user/userActions';
import {
  IMPORT_PHARMACIES_COLUMNS,
  SERVICE_QUESTION_TYPES,
  SIGNUP_LOGIN_ERROR_MESSAGES,
  SIGN_IN_UP_MODES,
  TABLE_NAMES,
  PHARMACY_OVERRIDES
} from './constant';
import {
  FilterProperties,
  PharmacyObject,
  PharmacyService,
  ServiceObject,
  ServiceQuestionObject,
  ServiceReferenceObject,
  UserWithPharmacyObject
} from './interfaces';

export const validateUsername = (name: string): string => {
  const trimmedName = name.trim();
  if (trimmedName === '') {
    return SIGNUP_LOGIN_ERROR_MESSAGES.USERNAME_EMPTY;
  }
  if (!new RegExp(/^[a-zA-Z ]{2,100}$/).test(trimmedName)) {
    return SIGNUP_LOGIN_ERROR_MESSAGES.USERNAME_INVALID;
  }
  return '';
};

export const validatePhoneNumber = (
  phone: string
): string => {
  if (phone === '') {
    return 'Phone number cannot be empty';
  }
  try {
    const phoneNumber = Number(
      phone.replace(/-/g, '').replace(/ /g, '')
    );
    if (
      phoneNumber >= 1000000000 &&
      phoneNumber <= 9999999999
    ) {
      return '';
    }
    return 'Phone number should be of 10 digits';
  } catch (err) {
    return 'Enter valid phone number';
  }
};

export const validateEmail = (
  email: string,
  mode = SIGN_IN_UP_MODES.USER_SIGN_UP
): string => {
  const trimmedEmail = email.trim();
  if (trimmedEmail === '') {
    return SIGNUP_LOGIN_ERROR_MESSAGES.EMAIL_EMPTY;
  }
  if (mode === SIGN_IN_UP_MODES.USER_SIGN_IN) {
    return '';
  }
  if (
    !new RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    ).test(trimmedEmail)
  ) {
    return SIGNUP_LOGIN_ERROR_MESSAGES.EMAIL_INVALID;
  }
  return '';
};

export const validatePassword = (
  password: string,
  mode = SIGN_IN_UP_MODES.USER_SIGN_UP
): string => {
  if (password === '') {
    return SIGNUP_LOGIN_ERROR_MESSAGES.PASSWORD_EMPTY;
  }
  if (mode === SIGN_IN_UP_MODES.USER_SIGN_IN) {
    return '';
  }
  if (password.length < 8) {
    return SIGNUP_LOGIN_ERROR_MESSAGES.PASSWORD_INVALID_LENGTH;
  }
  if (
    !new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$#!%*?&])[A-Za-z\\d@$#!%*?&]{8,}$'
    ).test(password)
  ) {
    return SIGNUP_LOGIN_ERROR_MESSAGES.PASSWORD_INVALID_CHARACTERS;
  }
  return '';
};

export const validateConfirmPassword = (
  password: string,
  confirmationPassword: string
) => {
  return password !== '' &&
    password !== confirmationPassword
    ? SIGNUP_LOGIN_ERROR_MESSAGES.PASSWORD_CONFIRMATION_MISMATCH
    : '';
};

export const getInitialsFromName = (
  name: string
): string => {
  const splitName = name.split(' ');
  const initials = splitName.map((part) => {
    return part[0].toUpperCase();
  });
  return `${initials[0]}${
    initials.length === 1
      ? ''
      : initials[initials.length - 1]
  }`;
};

export const getLatestServiceQuestionId = (
  questionList: ServiceQuestionObject[]
): number => {
  const minId = Math.min(
    ...questionList.map(
      (question) => question.serviceQuestionId
    )
  );
  return minId < 0 ? minId - 1 : -1;
};

export const getLatestServiceQuestionType = (
  questionType: string,
  questionList: ServiceQuestionObject[]
): string => {
  const levelQuestions = questionList.map((question) => {
    if (
      [
        SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
        SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
      ].includes(question.serviceQuestionType)
    ) {
      return 0;
    }
    const levelPart =
      question.serviceQuestionType.split(' ')[0];
    return Number(levelPart.charAt(levelPart.length - 1));
  });
  const latestLevelNumber =
    levelQuestions.length === 0
      ? 0
      : Math.max(...levelQuestions) + 1;
  return 'Level' + latestLevelNumber + ' Provider';
};

export const getLatestServiceReferenceId = (
  referenceList: ServiceReferenceObject[]
): number => {
  if (referenceList.length === 0) {
    return -1;
  }
  const minId = Math.min(
    ...referenceList.map(
      (reference) => reference.serviceReferenceId
    )
  );
  return minId < 0 ? minId - 1 : -1;
};

export const getAddress = (
  address1: string,
  address2: string | null,
  city: string,
  state: string,
  zip: string
) => {
  return `${address1}${
    address2 && address2.trim().length > 0
      ? ', ' + address2
      : ''
  }, ${city}, ${state}, ${zip}`;
};

export const hasSupplementalQuestions = (
  service: ServiceObject
): boolean => {
  return (
    service.serviceQuestions.filter((question) =>
      [
        SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
        SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
      ].includes(question.serviceQuestionType)
    ).length > 0
  );
};

export const getSupplementalQuestions = (
  service: ServiceObject
): ServiceQuestionObject[] => {
  return service.serviceQuestions.filter((serv) =>
    [
      SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL,
      SERVICE_QUESTION_TYPES.SUPPLEMENTAL
    ].includes(serv.serviceQuestionType)
  );
};

export const getServiceQuestionsByType = (
  questions: ServiceQuestionObject[],
  type: string
): ServiceQuestionObject[] => {
  if (
    [
      SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
      SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
    ].includes(type)
  ) {
    return questions.filter((question) =>
      [
        SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
        SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
      ].includes(question.serviceQuestionType)
    );
  }
  return questions.filter(
    (question) =>
      ![
        SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
        SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
      ].includes(question.serviceQuestionType)
  );
};

export const getProviderLevelFromType = (
  questionType: string
): string => {
  return [
    SERVICE_QUESTION_TYPES.SUPPLEMENTAL,
    SERVICE_QUESTION_TYPES.CUSTOM_SUPPLEMENTAL
  ].includes(questionType)
    ? 'None'
    : questionType.split(' ')[0];
};

export const getRoleFromToken = (token: string | null) => {
  if (token) {
    const decodedToken = JSON.parse(
      atob(token.split('.')[1])
    );
    return decodedToken.role;
  }
};

export const getUserProfileFromToken = (
  dispatch: DispatchType,
  token: string | null,
  userProfileToken: string | null
) => {
  if (
    token &&
    (!userProfileToken || token !== userProfileToken)
  ) {
    const decodedToken = JSON.parse(
      atob(token.split('.')[1])
    );
    loadUserProfile(dispatch, {
      myPharmacy: decodedToken.myPharmacy,
      userProfile: {
        email: decodedToken.email,
        name: decodedToken.name,
        role: decodedToken.role,
        userId: decodedToken.userId,
        token: token
      }
    } as UserWithPharmacyObject);
  }
};

export const filterPharamciesByFilter = (
  pharmacies: PharmacyObject[],
  pharmacyServicesMapping: PharmacyService[],
  filter: FilterProperties
): PharmacyObject[] => {
  if (pharmacies) {
    const locationFilterd = pharmacies.filter(
      (ph) =>
        (filter.pharmacyName === '' ||
          ph.pharmacyName
            .toLowerCase()
            .includes(filter.pharmacyName.toLowerCase())) &&
        (filter.city === '' ||
          ph.locationCity
            .toLowerCase()
            .includes(filter.city.toLowerCase())) &&
        (filter.county === '' ||
          ph.locationCounty
            .toLowerCase()
            .includes(filter.county.toLowerCase()))
    );
    if (filter.servicesOffered.length === 0) {
      return locationFilterd;
    }
    return locationFilterd.filter((pharmacy) =>
      filter.servicesOffered.every((serviceId) =>
        pharmacyServicesMapping.some(
          (mapping) =>
            mapping &&
            mapping.questionType ===
              SERVICE_QUESTION_TYPES.PROVIDER &&
            mapping.pharmacyRecordId ===
              pharmacy.recordId &&
            mapping.serviceId === serviceId
        )
      )
    );
  }
  return [] as PharmacyObject[];
};

export const convertExcelToJson = (
  excelData: string[][],
  tableName: string
): Record<string, string | number | null>[] => {
  const headers = excelData[0] as string[];

  if (
    tableName === TABLE_NAMES.PHARMACIES &&
    IMPORT_PHARMACIES_COLUMNS.some(
      (column) => !headers.includes(column)
    )
  ) {
    throw new Error(
      'Required columns are not present. Please recheck with template file.'
    );
  }

  const jsonData: Record<string, string | number | null>[] =
    [];

  excelData.map((dataRow: string[], index) => {
    if (index > 0) {
      const rowData: Record<
        string,
        string | number | null
      > = {};
      headers.map((header, itemIndex) => {
        if (
          [
            'locationLatitude',
            'locationLongitude'
          ].includes(header)
        ) {
          rowData[header] =
            !dataRow[itemIndex] || dataRow[itemIndex] === ''
              ? null
              : dataRow[itemIndex];
        } else {
          rowData[header] = dataRow[itemIndex] || '';
        }
      });
      jsonData.push(rowData);
    }
  });

  if (tableName === TABLE_NAMES.PHARMACIES) {
    jsonData.map((record) => {
      if ((record.permitNo as number) < 10) {
        record.permitNo = '0000' + record.permitNo;
      } else if ((record.permitNo as number) < 100) {
        record.permitNo = '000' + record.permitNo;
      } else if ((record.permitNo as number) < 1000) {
        record.permitNo = '00' + record.permitNo;
      } else if ((record.permitNo as number) < 10000) {
        record.permitNo = '0' + record.permitNo;
      } else {
        record.permitNo = record.permitNo as string;
      }
    });
  }

  return jsonData;
};

export const getDateInMySQLFormat = (
  date: Date
): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(
    2,
    '0'
  );
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(
    2,
    '0'
  );
  const seconds = String(date.getSeconds()).padStart(
    2,
    '0'
  );
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const getDateString = (date: Date): string => {
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

export const getDateTimeString = (date: Date): string => {
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
};

const getTwoDigit = (value: number): string => {
  return (value < 10 ? '0' : '') + value.toString();
};

export const getTimestamp = (): string => {
  const currentDate = new Date();
  const year = currentDate.getFullYear().toString();
  const month = getTwoDigit(currentDate.getMonth() + 1);
  const day = getTwoDigit(currentDate.getDate());
  const hours = getTwoDigit(currentDate.getHours());
  const minutes = getTwoDigit(currentDate.getMinutes());
  const seconds = getTwoDigit(currentDate.getSeconds());
  return year + month + day + hours + minutes + seconds;
};

const withinNorthCarolina = (
  lat: number,
  lon: number
): boolean => {
  const top = 36.5881334409244;
  const left = -84.32178200052;
  const right = -75.45981513195132;
  const bottom = 33.85116926668266;

  return (
    lat >= bottom &&
    lat <= top &&
    lon >= left &&
    lon <= right
  );
};

export const fixCoordinates = (
  pharmacy: PharmacyObject
): boolean => {
  let fixed = false;

  const lat = pharmacy.locationLatitude;
  const lon = pharmacy.locationLongitude;

  const combinations = [
    [lat, lon],
    [lat, -lon],
    [-lat, lon],
    [-lat, -lon],
    [lon, lat],
    [lon, -lat],
    [-lon, lat],
    [-lon, -lat]
  ];

  for (const [lat, lon] of combinations) {
    if (withinNorthCarolina(lat, lon)) {
      pharmacy.locationLatitude = lat;
      pharmacy.locationLongitude = lon;
      fixed = true;
      break;
    }
  }

  return fixed;
};

export const showFacilityEmail = (
  pharmacy: PharmacyObject
): boolean => {
  return !PHARMACY_OVERRIDES.HIDE_FACILITY_EMAIL.includes(
    pharmacy.permitNo
  );
};
