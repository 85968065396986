import { useNavigate } from 'react-router-dom';
import { VIEW_MODE } from '../../helpers/constant';
import { PharmacyCardProps } from '../../helpers/interfaces';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import {
  getAddress,
  showFacilityEmail
} from '../../helpers/utils';
import styles from './PharmacyCard.module.css';

const PharmacyCard: React.FC<PharmacyCardProps> = (
  props: PharmacyCardProps
): JSX.Element => {
  const navigate = useNavigate();
  const state = useSelector(
    (state: RootStateType) => state.pharmaciesLayout
  );
  return (
    <>
      <Grid
        item
        key={props.pharmacy.recordId}
        xs={12}
        sm={state.mode === VIEW_MODE.LIST_VIEW ? 12 : 6}
        md={state.mode === VIEW_MODE.LIST_VIEW ? 12 : 4}>
        <Card
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
          style={{ cursor: 'pointer' }}>
          <CardContent
            className={styles.pharmacyCard}
            style={{
              border: '1px solid rgba(0, 0, 0, 0.2)',
              borderRadius: 8,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              transition: 'box-shadow 0.3s ease'
            }}
            sx={{ flexGrow: 1 }}
            onClick={() => {
              navigate(
                `/pharmacy/${props.pharmacy.recordId}`
              );
            }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  align="left">
                  {props.pharmacy.pharmacyName}
                  <Divider
                    sx={{ border: '0.5px solid lightgray' }}
                  />
                </Typography>
              </Grid>
              {/* Address */}
              <Grid
                item
                xs={12}
                sm={
                  state.mode === VIEW_MODE.GRID_VIEW
                    ? 12
                    : 7
                }
                md={
                  state.mode === VIEW_MODE.GRID_VIEW
                    ? 12
                    : 5
                }>
                <Typography align="left" variant="h6">
                  {getAddress(
                    props.pharmacy.locationAddress1,
                    props.pharmacy.locationAddress2,
                    props.pharmacy.locationCity,
                    props.pharmacy.locationState,
                    props.pharmacy.locationZip
                  )}
                </Typography>
              </Grid>
              {/* County */}
              <Grid
                item
                xs={12}
                sm={
                  state.mode === VIEW_MODE.GRID_VIEW
                    ? 12
                    : 4
                }
                md={
                  state.mode === VIEW_MODE.GRID_VIEW
                    ? 12
                    : 3
                }>
                <Typography align="left" variant="h6">
                  <b>County:</b>{' '}
                  {props.pharmacy.locationCounty}
                </Typography>
              </Grid>
              {/* Facility Email */}
              {showFacilityEmail(props.pharmacy) && (
                <Grid
                  item
                  xs={12}
                  sm={
                    state.mode === VIEW_MODE.GRID_VIEW
                      ? 12
                      : 7
                  }
                  md={
                    state.mode === VIEW_MODE.GRID_VIEW
                      ? 12
                      : 4
                  }>
                  <Typography align="left" variant="h6">
                    <b>Email:</b>{' '}
                    {props.pharmacy.facilityEmail}
                  </Typography>
                </Grid>
              )}
              {/* Phone number */}
              <Grid
                item
                xs={12}
                sm={
                  state.mode === VIEW_MODE.GRID_VIEW
                    ? 12
                    : 4
                }
                md={
                  state.mode === VIEW_MODE.GRID_VIEW
                    ? 12
                    : 5
                }>
                <Typography align="left" variant="h6">
                  <b>Phone:</b> {props.pharmacy.phone}
                </Typography>
              </Grid>
              {/* Fax */}
              {props.pharmacy.fax && (
                <Grid
                  item
                  xs={12}
                  sm={
                    state.mode === VIEW_MODE.GRID_VIEW
                      ? 12
                      : 7
                  }
                  md={
                    state.mode === VIEW_MODE.GRID_VIEW
                      ? 12
                      : 3
                  }>
                  <Typography align="left" variant="h6">
                    <b>Fax:</b> {props.pharmacy.fax}
                  </Typography>
                </Grid>
              )}
              {/* Hours of operation */}
              {props.pharmacy.operationHours && (
                <Grid item xs={12} sm={12} md={12}>
                  <Typography align="left" variant="h6">
                    <b>Hours:</b>{' '}
                    {props.pharmacy.operationHours}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
          {props.pharmacy.website && (
            <CardActions
              sx={{ backgroundColor: '#b0bec5' }}>
              <Tooltip title={props.pharmacy.website}>
                <a
                  href={`${
                    props.pharmacy.website.includes('http')
                      ? ''
                      : 'https://'
                  }${props.pharmacy.website}`}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    display: 'flex'
                  }}
                  rel="noopener noreferrer">
                  <LanguageTwoToneIcon fontSize="small" />
                  <Typography ml={1}>Website</Typography>
                </a>
              </Tooltip>
            </CardActions>
          )}
        </Card>
      </Grid>
    </>
  );
};

export default PharmacyCard;
