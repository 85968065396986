import { DATA_LOAD_STATUS } from '../../helpers/constant';
import {
  FilterProperties,
  PharmacyObject
} from '../../helpers/interfaces';
import { ACTION_NAMES } from '../reduxActions';

const initialState = {
  status: DATA_LOAD_STATUS.STATUS_LOADING,
  pharmaciesList: [] as PharmacyObject[],
  filters: {
    pharmacyName: '',
    city: '',
    county: '',
    servicesOffered: [] as number[]
  } as FilterProperties,
  paginationPageCapacity: 10,
  currentPagination: 1
};

export const pharmaciesReducer = (
  state = initialState,
  action: {
    type: string;
    payload:
      | PharmacyObject[]
      | FilterProperties
      | string
      | number;
  }
) => {
  switch (action.type) {
    case ACTION_NAMES.LOAD_PHARMACIES:
      return {
        ...state,
        status: DATA_LOAD_STATUS.STATUS_LOADING,
        pharmaciesList: [] as PharmacyObject[]
      };
    case ACTION_NAMES.LOAD_PHARMACIES_SUCCESS:
      return {
        ...state,
        status: DATA_LOAD_STATUS.STATUS_DONE,
        pharmaciesList: action.payload as PharmacyObject[]
      };
    case ACTION_NAMES.UPDATE_PAGINATION:
      return {
        ...state,
        currentPagination: action.payload as number
      };
    case ACTION_NAMES.UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload as FilterProperties
      };
    default:
      return state;
  }
};
