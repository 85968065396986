import {
  MapContainer,
  Marker,
  // Popup,
  TileLayer
} from 'react-leaflet';
import { GeoMapProps } from '../../helpers/interfaces';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const GeoMap: React.FC<GeoMapProps> = (
  props: GeoMapProps
): JSX.Element => {
  const googleMapsLink = `https://www.google.com/maps?q=${props.address}`;

  return (
    <>
      <MapContainer
        center={[props.latitude, props.longitude]}
        zoom={15}
        style={{ height: '100%', width: '100%' }}
        scrollWheelZoom={false}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker
          position={[
            props.latitude,
            props.longitude
          ]}></Marker>
      </MapContainer>
      <Button
        variant="contained"
        size="small"
        sx={{ mt: 1, mb: 1 }}>
        <Link
          style={{ textDecoration: 'none', color: 'white' }}
          to={googleMapsLink}
          target="_blank">
          Get Directions
        </Link>
      </Button>
    </>
  );
};

export default GeoMap;
