import { VIEW_MODE } from '../../helpers/constant';
import { ACTION_NAMES } from '../reduxActions';

const initialState = {
  mode: VIEW_MODE.LIST_VIEW
};

export const pharmaciesLayoutReducer = (
  state = initialState,
  action: { type: string }
) => {
  switch (action.type) {
    case ACTION_NAMES.UPDATE_PHARMACY_LAYOUT:
      return {
        ...state,
        mode:
          state.mode === VIEW_MODE.LIST_VIEW
            ? VIEW_MODE.GRID_VIEW
            : VIEW_MODE.LIST_VIEW
      };
    default:
      return state;
  }
};
