import React from 'react';
import {
  Typography,
  Container,
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Tooltip
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { updateFilters } from '../../redux/pharmacies/pharmaciesActions';

const FilterSection: React.FC = () => {
  const pharmaciesState = useSelector(
    (state: RootStateType) => state.pharmacies
  );

  const servicesState = useSelector(
    (state: RootStateType) => state.services
  );

  const dispatch = useDispatch();

  const updateFilterValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updateFilters({
        ...pharmaciesState.filters,
        [event.target.name]: event.target.value
      })
    );
  };

  const updateServiceFilters = (id: number) => {
    if (
      pharmaciesState.filters.servicesOffered.includes(id)
    ) {
      const updatedList =
        pharmaciesState.filters.servicesOffered.filter(
          (service) => service !== id
        );
      dispatch(
        updateFilters({
          ...pharmaciesState.filters,
          servicesOffered: updatedList
        })
      );
    } else {
      dispatch(
        updateFilters({
          ...pharmaciesState.filters,
          servicesOffered: [
            ...pharmaciesState.filters.servicesOffered,
            id
          ]
        })
      );
    }
  };

  return (
    <>
      {
        <Container
          style={{
            border: '0.3px solid rgba(0, 0, 0, 0.05)',
            borderRadius: 8,
            boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.1)',
            transition: 'box-shadow 0.3s ease'
          }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            fontSize="large"
            ml={2}>
            Filters
          </Typography>

          <Divider />

          <Box>
            <Typography
              variant="h6"
              fontStyle="italic"
              mt={1}
              key="locationFilters">
              Location Filters
            </Typography>
            <TextField
              label="Name"
              name="pharmacyName"
              value={pharmaciesState.filters.pharmacyName}
              onChange={updateFilterValue}
              fullWidth
              margin="normal"
              key="name"
            />
            <TextField
              label="City"
              name="city"
              value={pharmaciesState.filters.city}
              onChange={updateFilterValue}
              fullWidth
              margin="normal"
              key="city"
            />
            <TextField
              label="County"
              name="county"
              value={pharmaciesState.filters.county}
              onChange={updateFilterValue}
              fullWidth
              margin="normal"
              key="county"
            />
            <Divider />
            <Typography
              variant="h6"
              fontStyle="italic"
              mt={1}
              key="serviceFilters">
              Service Filters
            </Typography>
            <FormGroup>
              {servicesState.servicesList.map((service) => {
                return (
                  <>
                    <FormControlLabel
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start'
                      }}
                      key={service.serviceId}
                      control={
                        <Checkbox
                          checked={pharmaciesState.filters.servicesOffered.includes(
                            service.serviceId
                          )}
                          onChange={() =>
                            updateServiceFilters(
                              service.serviceId
                            )
                          }
                        />
                      }
                      label={
                        <Tooltip
                          placement="right-end"
                          title={
                            service.publicServiceDefinition
                          }
                          key={
                            'tooltip' + service.serviceId
                          }>
                          <Typography
                            variant="body1"
                            align="left"
                            style={{ marginTop: '4%' }}>
                            {service.serviceName}
                          </Typography>
                        </Tooltip>
                      }
                    />
                    <Divider
                      sx={{
                        marginTop: '2%',
                        marginBottom: '2%',
                        borderBottomWidth: '1px',
                        borderColor: 'lightgray'
                      }}
                      key={'divider' + service.serviceId}
                    />
                  </>
                );
              })}
            </FormGroup>
          </Box>
        </Container>
      }
    </>
  );
};

export default FilterSection;
