import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { useEffect, useState } from 'react';
import SortFilterTable from '../../sharedComponents/SortFilterTable/SortFilterTable';
import { filterPharamciesByFilter } from '../../helpers/utils';
import { SERVICE_QUESTION_TYPES } from '../../helpers/constant';

const ReportPharmacyServiceStats: React.FC =
  (): JSX.Element => {
    const pharmaciesState = useSelector(
      (state: RootStateType) => state.pharmacies
    );

    const servicesState = useSelector(
      (state: RootStateType) => state.services
    );

    const mappingsState = useSelector(
      (state: RootStateType) =>
        state.pharmaciesServicesMapping
    );

    const [tableData, setTableData] = useState<
      { service: string; pharmacyCount: number }[]
    >([]);

    useEffect(() => {
      let counter = 0;
      const serviceData = servicesState.servicesList.map(
        (service) => {
          counter = counter + 1;
          return {
            index: counter,
            service: service.serviceName,
            pharmacyCount: filterPharamciesByFilter(
              pharmaciesState.pharmaciesList,
              mappingsState.pharmaciesAndServicesList,
              {
                pharmacyName: '',
                city: '',
                county: '',
                servicesOffered: [service.serviceId]
              }
            ).length
          };
        }
      );

      const pharmaciesWithServices =
        mappingsState.pharmaciesAndServicesList
          .filter(
            (m) =>
              m &&
              m.questionType ===
                SERVICE_QUESTION_TYPES.PROVIDER &&
              m.response !== 'None'
          )
          .map((mp) => mp?.pharmacyRecordId) || [];
      const noServiceData =
        pharmaciesState.pharmaciesList.filter(
          (p) =>
            !pharmaciesWithServices.includes(p.recordId)
        );
      setTableData([
        ...serviceData,
        {
          index: counter + 1,
          service: 'No Service',
          pharmacyCount: noServiceData.length || 0
        }
      ]);
    }, [pharmaciesState, servicesState, mappingsState]);

    const tableColumns: {
      columnKey: string;
      columnDisplay: string;
      hidden: boolean;
    }[] = [
      {
        columnKey: 'index',
        columnDisplay: '#',
        hidden: false
      },
      {
        columnKey: 'service',
        columnDisplay: 'Service',
        hidden: false
      },
      {
        columnKey: 'pharmacyCount',
        columnDisplay: 'Number of Pharmacies',
        hidden: false
      }
    ];

    return (
      <>
        <SortFilterTable
          columnList={tableColumns}
          originalData={tableData}
          originalDataCount={-1}
          tableDataItem="NA"
        />
      </>
    );
  };

export default ReportPharmacyServiceStats;
