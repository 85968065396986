import {
  BrowserRouter,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import NavigationBar from '../NavigationBar/NavigationBar';
import PharmacyPage from '../../views/PharmacyPage/PharmacyPage';
import UserSignUp from '../../views/UserSignUp/UserSignUp';
import UserSignIn from '../../views/UserSignIn/UserSignIn';
import ServicesContainer from '../../views/ServicesContainer/ServicesContainer';
import ManagePharmacy from '../../views/ManagePharmacy/ManagePharmacy';
import ServiceRequests from '../../views/ServiceRequests/ServiceRequests';
import ResetPassword from '../../views/ResetPassword/ResetPassword';
import SnackbarComponent from '../SnackbarComponent/SnackbarComponent';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRoleFromToken,
  getUserProfileFromToken
} from '../../helpers/utils';
import { RootStateType } from '../../redux/store';
import { USER_ROLES } from '../../helpers/constant';
import ImportExport from '../../views/ImportExport/ImportExport';
import GiveFeedback from '../../views/Feedback/GiveFeedback';
import ViewFeedback from '../../views/Feedback/ViewFeedback';
import PharmaciesLayout from '../../views/PharmaciesLayout/PharmaciesLayout';
import Home from '../../views/Home/Home';
import ForgotPassword from '../../views/ForgotPassword/ForgotPassword';
import ManageAdmins from '../../views/ManageAdmins/ManageAdmins';
import TrackMails from '../../views/TrackMails/TrackMails';
import Reports from '../../views/Reports/Reports';

const Main: React.FC = (): JSX.Element => {
  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  const [token, setToken] = useState<string | null>(
    userState.userProfile?.token ||
      localStorage.getItem('token')
  );

  const [role, setRole] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    setToken(
      userState.userProfile?.token ||
        localStorage.getItem('token')
    );
  }, [userState.userProfile]);

  useEffect(() => {
    setRole(getRoleFromToken(token));
    getUserProfileFromToken(
      dispatch,
      token,
      userState.userProfile
        ? userState.userProfile.token
        : null
    );
  }, [token]);

  return (
    <>
      <BrowserRouter>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/pharmacies"
            element={<PharmaciesLayout />}
          />
          <Route
            path="/pharmacy/:id"
            element={<PharmacyPage />}
          />
          <Route
            path="/services"
            element={
              token ? (
                [
                  USER_ROLES.ADMIN,
                  USER_ROLES.SUPERADMIN
                ].includes(role) ? (
                  <ServicesContainer />
                ) : (
                  <Navigate to="/" replace />
                )
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />
          <Route path="/signup" element={<UserSignUp />} />
          <Route path="/signin" element={<UserSignIn />} />

          <Route
            path="/managePharmacy/:recordId"
            element={
              token ? (
                <ManagePharmacy />
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />

          <Route
            path="/serviceRequests"
            element={
              token ? (
                [
                  USER_ROLES.ADMIN,
                  USER_ROLES.SUPERADMIN
                ].includes(role) ? (
                  <ServiceRequests />
                ) : (
                  <Navigate to="/" replace />
                )
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />
          <Route
            path="/resetPassword"
            element={
              token ? (
                <ResetPassword />
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />
          <Route
            path="/forgotpassword"
            element={
              token ? (
                <Navigate to="/" replace />
              ) : (
                <ForgotPassword />
              )
            }
          />

          <Route
            path="/importexport"
            element={
              token ? (
                [
                  USER_ROLES.ADMIN,
                  USER_ROLES.SUPERADMIN
                ].includes(role) ? (
                  <ImportExport />
                ) : (
                  <Navigate to="/" replace />
                )
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />

          <Route
            path="/manageAdmins"
            element={
              token ? (
                role === USER_ROLES.SUPERADMIN ? (
                  <ManageAdmins />
                ) : (
                  <Navigate to="/" replace />
                )
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />

          <Route
            path="/giveFeedback"
            element={<GiveFeedback />}
          />

          <Route
            path="/feedback"
            element={
              token ? (
                [
                  USER_ROLES.ADMIN,
                  USER_ROLES.SUPERADMIN
                ].includes(role) ? (
                  <ViewFeedback />
                ) : (
                  <Navigate to="/" replace />
                )
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />

          <Route
            path="/trackMails"
            element={
              token ? (
                [
                  USER_ROLES.ADMIN,
                  USER_ROLES.SUPERADMIN
                ].includes(role) ? (
                  <TrackMails />
                ) : (
                  <Navigate to="/" replace />
                )
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />

          <Route
            path="/reports"
            element={
              token ? (
                [
                  USER_ROLES.ADMIN,
                  USER_ROLES.SUPERADMIN
                ].includes(role) ? (
                  <Reports />
                ) : (
                  <Navigate to="/" replace />
                )
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />
        </Routes>
        <SnackbarComponent />
      </BrowserRouter>
    </>
  );
};

export default Main;
